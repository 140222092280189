<template>
  <div>
    <b-container>
      <div id="qrCode" class="text-center qrCode-div">
        <qrcode-vue
          :value="relatorioData.materiais[indexMaterial].code"
          level="H"
          class="qr-code"
          renderAs="svg"
          size="75"
        ></qrcode-vue>
        <label class="qr-code-label mb-4 descricao-material">
          {{ relatorioData.materiais[indexMaterial].shownId }}
            - {{ relatorioData.materiais[indexMaterial].descricao }}
        </label>
        <br />
        <label class="qr-code-label mb-4 qrCode-INVCXCuuid no-print">
          {{ relatorioData.materiais[indexMaterial].code }}
        </label>
        <qrcode-vue
          :value="relatorioData.materiais[indexMaterial].code"
          level="H"
          class="qr-code printOnly"
          renderAs="svg"
          size="75"
        ></qrcode-vue>
        <label class="qr-code-label mb-4 descricao-material printOnly">
          {{ relatorioData.materiais[indexMaterial].id }}
            - {{ relatorioData.materiais[indexMaterial].descricao }}
        </label>

        <qrcode-vue
          :value="relatorioData.materiais[indexMaterial].code"
          level="H"
          class="qr-code printOnly"
          renderAs="svg"
          size="75"
        ></qrcode-vue>
        <label class="qr-code-label mb-4 descricao-material printOnly">
          {{ relatorioData.materiais[indexMaterial].id }}
            - {{ relatorioData.materiais[indexMaterial].descricao }}
        </label>
      </div>
    </b-container>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  components: {
    QrcodeVue,
  },

  props: {
    relatorioData: {
      type: Object,
      default: null,
      required: true,
    },
    indexMaterial: {
      type: Number,
      default: null,
      required: true,
    },
  },
};
</script>

<style scoped>

@media print {
  .qrCode-printer {
    height: 10.4cm !important;
    width: 2.85cm !important;
    padding: 0.2cm !important;
    padding-top: 0.5cm !important;
  }

  .qrCode-div {
    margin-bottom: 2.3cm !important;
  }

  .qr-code {
    margin-left: 0.55cm !important;
    height: 2cm !important;
    width: 2.2cm !important;
  }

  .descricao-material {
    width: 100% !important;
    font-size: 0.3cm !important;
    height: 0.6cm !important;
    margin-top: 0cm !important;
    margin-left: 0.1cm !important;
    margin-bottom: 2.9cm !important;
  }

  .qrCode-INVCXCuuid {
    width: 100% !important;
    font-size: 0.26cm !important;
    height: 1.5cm !important;
    margin-left: 0.1cm !important;
  }

  .printOnly {
    display: inline-block !important;
  }

  .no-print {
    display: none;
  }
}

.qr-code-label {
  margin: auto;
  font-style: italic;
  color: #8f8f8f;
}

.printOnly {
  display: none;
}

</style>
